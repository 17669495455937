import "./App.css";

function App() {
  return (
    <div className="container-fluid w-100 h-100 d-flex">
      <img src="/frlogo.png" className="img-fluid" id="logo" alt="Logo" />
    </div>
  );
}

export default App;
